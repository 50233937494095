$(document).ready(function () {
  const tables = $('table');
  for (let t = 0; t < tables.length; ++t) {
    const th = $(tables[t]).find('thead tr th');
    const tr = $(tables[t]).find('tbody tr');
    for (let r = 0; r < tr.length; ++r) {
      const row = $(tr[r]);
      const td = row.find('td');
      for (let d = 0; d < td.length; ++d) {
        $(td[d]).attr('data-title', $(th[d]).text() + ':');
      }
    }
  }
});
